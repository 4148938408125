import { Link } from 'gatsby';
import React, { useState } from 'react';

import laptopImage from '../../images/bg/laptop.png';
import iconWhite from '../../images/icon-white.png';

import { Icon } from '@iconify/react';
import figmaIcon from '@iconify/icons-logos/figma';
import cloudflareIcon from '@iconify/icons-logos/cloudflare';
import gatsbyIcon from '@iconify/icons-logos/gatsby';
import SecondaryButton from '../Buttons/SecondaryButton';

const FormDiscussion = () => {
  const [data, setData] = useState({});

  const handleForm = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, message } = data;
    const subject = "Hi Dicka, i want to discussing about a ...";
    const body = `Hello, my name is ${name} \n ${message}`;
    const mailUrl = `mailto:dickaismaji@gmail.com?subject=${subject}&body=${body}`;
    window.location.href = mailUrl;
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='pb-4'>
        <input name="name" onChange={handleForm} className="w-full rounded-lg bg-white py-2 px-4" type="text" placeholder="Your name ..." style={{ background: '#ffffff1f' }} required />
      </div>
      <div className='pb-4'>
        <textarea name="message" onChange={handleForm} className="w-full rounded-lg bg-white py-2 px-4 text-white" rows="5" placeholder="Write a message ..." style={{ background: '#ffffff1f', resize: 'none' }} required></textarea>
      </div>
      <SecondaryButton type="submit" className="w-full">
        Send
      </SecondaryButton>
    </form>
  );
};

export default function Footer() {
  return (
    <footer>
      <div style={{
        backgroundImage: `url(${laptopImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}>
        <div className="container px-4 mx-auto pt-16 py-6">
          <div className="flex w-full flex-col md:flex-row justify-between pb-12">
            <div className='basis-1/1 sm:basis-1/2 lg:basis-1/3 w-full text-center px-4 mb-4'>
              <div className='pb-4'>
                <img className='mx-auto' src={iconWhite} alt="Dicka Ismaji" />
              </div>
              <small className="text-center">A boy that entushiast <br /> with Technology</small>
            </div>
            <div className='basis-1/1 sm:basis-1/2 lg:basis-1/3 w-full px-4 mb-4'>
              <h4 className='font-bold pb-4'>Navigation Link</h4>
              <ul>
                <li className='pb-4 font-thin hover:font-bold'>
                  <Link to="/">Home</Link>
                </li>
                <li className='pb-4 font-thin hover:font-bold'>
                  <Link to="/blog">Blog</Link>
                </li>
                <li className='pb-4 font-thin hover:font-bold'>
                  <Link to="/portfolio">Portfolio</Link>
                </li>
                <li className='pb-4 font-thin hover:font-bold'>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
            <div className='basis-1/1 sm:basis-1/2 lg:basis-1/3 w-full px-4 mb-4'>
              <h4 className='font-bold pb-4 text-center'>For business inquiry</h4>
              <FormDiscussion />
            </div>
          </div>
          <div className='text-center'>
            <small>Images by unsplash</small>
            <div>
              <p className='font-thin'>
                Designed with <Icon className='mx-2 inline' icon={figmaIcon} />
                Developed with <Icon className='mx-2 inline' color="white" icon={gatsbyIcon} />
                Deployed with <Icon className='mx-2 inline' color="white" icon={cloudflareIcon} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}