import React, { useEffect, useState } from 'react';
import { Link } from "gatsby";
import { globalHistory, } from '@reach/router';
import { FiSun, FiMoon } from 'react-icons/fi';

import { useTheme } from '../../context/ThemeContext';

export default function NavbarComponent() {
  const theme = useTheme();

  const trimLastSlash = (str) => str.lastIndexOf('/') ? str.substring(0, str.lastIndexOf('/')) : str;

  const [path, setPath] = useState(trimLastSlash(globalHistory.location.pathname));

  useEffect(() => {
    setPath(trimLastSlash(globalHistory.location.pathname));
  }, [setPath]);

  return (
    <header>
      <div className="container p-4">
        <div className="flex justify-between items-center">
          <nav className="py-5">
            <ul className="flex justify-center gap-8">
              <li className={`transition-all duration-500 hover:font-bold ${path === '/' ? 'font-bold' : 'font-thin'}`}>
                <Link to="/">Home</Link>
              </li>
              <li className={`transition-all duration-500 hover:font-bold ${path === '/blog' ? 'font-bold' : 'font-thin'}`}>
                <Link to="/blog">Blog</Link>
              </li>
              <li className={`transition-all duration-500 hover:font-bold ${path === '/portfolio' ? 'font-bold' : 'font-thin'}`}>
                <Link to="/portfolio">Portfolio</Link>
              </li>
              <li className={`transition-all duration-500 hover:font-bold ${path === '/contact' ? 'font-bold' : 'font-thin'}`}>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
          </nav>
          <button onClick={theme.toggleTheme}>{theme.darkMode ? <FiSun /> : <FiMoon />}</button>
        </div>
      </div>
    </header>
  );
}