import React from 'react';
import { Helmet } from 'react-helmet';
import gatsbyConfig from '../../../gatsby-config';

export default function Seo({
  title,
  description,
  image,
  themeColor,
  keywords,
  type,
  robots,
  language
}) {
  return (
    <Helmet
      htmlAttributes={{
        lang: 'en'
      }}
    >
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <title>{title || "Dicka Ismaji Personal Website"}</title>
      <meta name="title" property="og:title" content={title || `Dicka Ismaji Personal Website`} />
      <meta name="description" property="og:description" content={description || `Hello, my name is Dicka, I am one of the tech enthusiasts in this world, I like to write code especially on website development such as Frontend and Backend`} />
      <meta name="keywords" content={keywords || "dicka ismaji, dickaismaji, Dicka Ismaji, dicka, ismaji, dicka88, dickais, TheDKnight, imdk"} />
      <meta name="robots" content={robots || "index, follow"} />
      <meta name="image" property="og:image" content={`${gatsbyConfig.siteMetadata.siteUrl}/images/dickaismaji-cover.png`} />
      <meta name="language" content={language || "English"} />
      <meta name="revisit-after" content="1 days" />
      <meta name="author" content="Dicka Ismaji" />
      <meta name="type" content={type || "Website"} />
      <meta name="og:type" content="Website" />
      <meta name="og:title" content={title || "Dicka Ismaji Personal Website"} />
      <meta name="og:description" content={description || "Hello, my name is Dicka, I am one of the tech enthusiasts in this world, I like to write code especially on website development such as Frontend and Backend"} />
      <meta name="og:url" content={gatsbyConfig.siteMetadata.siteUrl} />
      <meta name="og:image" content={image || `${gatsbyConfig.siteMetadata.siteUrl}/images/dickaismaji-cover.png`} />
      <meta name="theme-color" content={themeColor || "#000"} />
    </Helmet>
  );
}