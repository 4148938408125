import React from 'react';
import NavbarComponent from '../Navbar/NavbarComponent';
import Footer from '../Partials/Footer';

import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader";
import Seo from '../Partials/Seo';
import { ThemeContextProvider } from '../../context/ThemeContext';
deckDeckGoHighlightElement();

export default function MainTemplate(props) {
  return (
    <ThemeContextProvider>
      <Seo />
      <NavbarComponent />
      <main className="container px-4 pb-36 mx-auto">
        {props.children}
      </main>
      <Footer />
    </ThemeContextProvider>
  );
}