import React from 'react';
import { createContext, useCallback, useContext, useEffect, useState } from "react";

const ThemeContext = createContext({
  darkMode: true,
  toggleTheme: () => { }
});


const ThemeContextProvider = ({ children }) => {
  const initialTheme = typeof localStorage === "undefined" ? false : JSON.parse(localStorage?.getItem('darkmode')) || false;
  const [darkMode, setDarkMode] = useState(initialTheme);

  const toggleTheme = useCallback(() => {
    setDarkMode((prev) => !prev);
  }, []);

  useEffect(() => {
    const root = document.documentElement;

    root.classList.add(darkMode ? "dark" : "light");
    root.classList.remove(darkMode ? "light" : "dark");

    localStorage.setItem('darkmode', darkMode);
  }, [darkMode]);

  const value = {
    darkMode,
    toggleTheme
  };

  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
export { ThemeContextProvider, ThemeContext };
export const useTheme = () => useContext(ThemeContext);