module.exports = {
  siteMetadata: {
    siteUrl: "https://dickaismaji.com",
    title: "Dicka Ismaji",
  },
  plugins: [
    "gatsby-plugin-image",
    "gatsby-plugin-react-helmet",
    "gatsby-plugin-sitemap",
    "gatsby-plugin-postcss",
    "gatsby-plugin-sass",
    {
      resolve: "gatsby-plugin-manifest",
      options: {
        icon: "src/images/icon.png",
      },
    },
    "gatsby-plugin-mdx",
    "gatsby-plugin-sharp",
    "gatsby-transformer-sharp",
    "gatsby-transformer-remark",
    'gatsby-plugin-smoothscroll',
    {
      resolve: "gatsby-transformer-remark",
      options: {
        plugins: [
          "gatsby-remark-autolink-headers",
          {
            resolve: `gatsby-remark-images`,
            options: {
              maxWidth: 400
            }
          },
          `gatsby-remark-lazy-load`,
          {
            resolve: "gatsby-remark-highlight-code",
            options: {
              terminal: "carbon",
              theme: "blackboard"
            }
          },
        ],
      },
    },
    {
      resolve: "gatsby-source-filesystem",
      options: {
        name: "images",
        path: "./src/images/",
      },
      __key: "images",
    },
    {
      resolve: "gatsby-source-filesystem",
      options: {
        name: "pages",
        path: "./src/pages/",
      },
      __key: "pages",
    },
    {
      resolve: "gatsby-source-filesystem",
      options: {
        name: "blog",
        path: "./src/data/blogs",
      },
      __key: "blog"
    },
    {
      resolve: `gatsby-plugin-disqus`,
      options: {
        shortname: `dickaismaji`
      }
    },
  ],
};
